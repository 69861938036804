import Layout from "../components/Layout"
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"

const TermsPage = ({ data }) => {
  const {
    content,
    header_image,
    title,
  } = data.allPrismicTermsPage.edges[0].node.data
  return (
    <Layout>
      <SEO title="Terms and Conditions" />
      <div className="terms-page">
        <div className="terms-page__image-container">
          <h1 className="terms-page__title">{title.text}</h1>
          <Img fluid={header_image.fluid} />
        </div>
        <div className="terms-page__content-container">
          <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TermsPageQuery {
    allPrismicTermsPage {
      edges {
        node {
          data {
            content {
              html
            }
            header_image {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`

export default TermsPage
